export const commonEnvironment = {
  production: false,
  sso: {
    subdomain: 'iot4bos-dev',
    domain: 'https://iot4bos-dev.auth.eu-west-1.amazoncognito.com',
    clientId: '4d8bpmnelnf2mkljakfakrvpub',
    idpIdentifier: 'okta',
    responseType: 'code',
    redirectUri: 'login/callback'
  },
  cognito: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_3kPklo82Y',
    clientId: '4d8bpmnelnf2mkljakfakrvpub'
  },
  api: {
    url: '/api/v1',
    url_v2: '/api/v2',
    key: '',
    endpoints: {
      xmqttDevices: '/devices?deviceIncomingConnectorId=MQT002',
      devices: '/devices',
      deviceDetails: '/device-details',
      entities: '/entities',
      users: '/users',
      usersLightWeight: '/directory/users',
      affiliates: '/directory/entity-affiliates',
      operators: '/directory/device-operators',
      timezones: '/directory/windows/timezones',
      tags: '/tag-categories',
      xFlowOperations: '/operations/xflow',
      deviceOperations: '/operations/devices'
    }
  },
  storage: {
    appPrefix: 'xmqtt'
  },
  apiKeys: {
    agGrid:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-072579}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ALIZENT_INTERNATIONAL}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_January_2027}____[v3]_[01]_MTc5OTcxMjAwMDAwMA==7e14622e3e03c3c787c4a2329ae238a3'
  }
};
